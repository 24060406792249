import './bootstrap';
import { q, forEachIfArray, getElementDistanceFromTop } from './utilities';
import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

// %%%%%%%%%%%%%%%%%%%%%%%
// Hero slider
const breakpoint = 768;

let heroImgs = [];
let vpw = window.innerWidth;
let vph = window.innerHeight;
if (vpw > 1440) vpw = 1440;
let heroAspectRatio; // ( full hero width / full hero height )
const heroSmallRatio = 0.8854; // ( small hero width / full hero width )
const cssDuration = 1;
const jsDuration = cssDuration * 1000;
updateOnBreakPoint();
q('.hero .img-wrap img').forEach((heroSlide) => {
  heroImgs.push(heroSlide);
});
const heroUsedForSizing = heroImgs[1];
let heroImgWidths = [];
const innerWrapEl = q('.inner-wrap');
let slideIndex = 0;
let heroOffset = 0;
let imgSuffix;

function updateHeroImages() {
  if (vpw < breakpoint) {
    imgSuffix = 'm';
  } else {
    imgSuffix = 'd';
  }
  q('.hero .img-wrap img').forEach((img) => {
    img.src = `/images/hero-${img.dataset.id}_${imgSuffix}.jpg`;
  });
}
updateHeroImages();

function updateOnBreakPoint() {
  if (vpw < breakpoint) {
    heroAspectRatio = 1.8079;
  } else {
    heroAspectRatio = 2.6617;
  }
}

function slideHeroes(heroOffset) {
  return innerWrapEl.style.transform = `translateX(-${heroOffset}px)`;
}

function heroSlider() {
  if (slideIndex < heroImgs.length) { // Move to next slide
    heroOffset += heroImgs[slideIndex].offsetWidth;
    slideHeroes(heroOffset);
  } else { // Move to last slide and reset
    heroOffset += heroImgs[0].offsetWidth;
    slideHeroes(heroOffset);
    slideIndex = 0;
    setTimeout(() => {
      innerWrapEl.style.transition = 'none';
    }, jsDuration);
    setTimeout(() => {
      heroOffset = heroImgs[0].offsetWidth - (vpw / 2);
      innerWrapEl.style.transform = `translateX(-${heroOffset}px)`;
    }, jsDuration + 100);
    setTimeout(() => {
      innerWrapEl.style.transition = `all ${cssDuration}s ease-in-out`;
    }, 2 * jsDuration + 100);
  }
}

function updateHeroSlider() {
  vpw = window.innerWidth;
  if (vpw > 1440) vpw = 1440;

  updateOnBreakPoint();
  setHeroImgWidths();

  q('.hero-wrap').style.height = Math.round(vpw / heroAspectRatio) + 'px';

  if (slideIndex === 0) {
    heroOffset = heroImgs[0].offsetWidth - (vpw / 2);
  } else {
    heroOffset = 0;
    for (let i = 0; i <= slideIndex; i++) {
      heroOffset += heroImgs[i].offsetWidth;
    }
    heroOffset -= (vpw / 2);
  }
  slideHeroes(heroOffset);
}

function setHeroImgWidths() {
  heroImgWidths = [
    vpw * heroSmallRatio,
    vpw,
    vpw * heroSmallRatio,
  ];
  heroImgWidths.forEach((width, i) => {
    heroImgs[i].style.width = Math.round(width) + 'px';
  });
  forEachIfArray(q('.img-wrap-1 img'), (el) => {
    el.style.width = Math.round(heroImgWidths[0]) + 'px';
  });
  forEachIfArray(q('.img-wrap-2 img'), (el) => {
    el.style.width = Math.round(heroImgWidths[1]) + 'px';
  });
}
setHeroImgWidths();

q('.hero-wrap').style.height = Math.round(heroUsedForSizing.offsetWidth / heroAspectRatio) + 'px';

// Add overflow slides
const cloneHeroImg1 = heroImgs[0].parentNode.cloneNode(true);
const cloneHeroImg2 = heroImgs[1].parentNode.cloneNode(true);
innerWrapEl.appendChild(cloneHeroImg1);
innerWrapEl.appendChild(cloneHeroImg2);

setTimeout(() => {
  innerWrapEl.style.transition = `all ${cssDuration}s ease-in-out`;
}, 1000);

// Set position 1
heroOffset += heroImgs[0].offsetWidth - (vpw / 2);
innerWrapEl.style.transform = `translateX(-${heroOffset}px)`;

setInterval(() => {
  slideIndex++;
  heroSlider();
}, 3000);

window.addEventListener('resize', () => {
  vpw = window.innerWidth;
  vph = window.innerHeight;
  updateHeroImages();
  updateHeroSlider();
});


// %%%%%%%%%%%%%%%%%%%%%%%
// Section 2 image-logo switcher

const sec_2_modules = APP_content.sec_2.modules;

const swiperOptions = {
  modules: [EffectFade, Autoplay],
  effect: 'fade',
  autoplay: {
    delay: 4000,
  },
  loop: true,
  allowTouchMove: false,
};

const logoSwiper = new Swiper('.section-2 .swiper.logo-wrap', swiperOptions);

const imgSwiper = new Swiper('.section-2 .swiper.img-wrap', swiperOptions);

forEachIfArray(imgSwiper, (swiper) => {
  swiper.on('slideChange', (e) => {
    const row = e.el.dataset.id;
    const slide = sec_2_modules[row - 1].slides[e.realIndex];
    const captionEl = q(`.section-2 .module-${row} .caption`);

    logoSwiper.slideNext;

    if (slide.img_caption) {
      captionEl.textContent = slide.img_caption;
    } else {
      captionEl.textContent = '';
    }
  });
});


// %%%%%%%%%%%%%%%%%%%%%%%
// Mobile sticky CTA

const linkBtn = q('#link-btn');
const linkBtnFromTop = getElementDistanceFromTop(linkBtn);
const btnOffset = 93;

function updateMobileStickyButton() {
  if (window.scrollY - btnOffset > (linkBtnFromTop - vph)) {
    linkBtn.classList.add('fixed');

    if (vpw >= breakpoint) {
      linkBtn.classList.remove('fixed');
    } else {
      linkBtn.classList.add('fixed');
    }

  } else {
    linkBtn.classList.remove('fixed');
  }
}

updateMobileStickyButton();

window.addEventListener('scroll', updateMobileStickyButton);
window.addEventListener('resize', updateMobileStickyButton);
